import React, { useState, useEffect } from 'react';
import { Grid, Stack, Typography, Button } from '@mui/material';
import { DataGrid, GridSortModel } from '@mui/x-data-grid';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useNavigate } from 'react-router-dom';
import { getAllObservations } from '../../services/apiCalls'; // Adjust the import path as necessary
import PageType from "../../customTypes/PageType"; // Adjust the import path as necessary
import { FullObservationResponse } from '../../customTypes/ObservationsApiResponseType';

const columns = [
    { field: '_id', headerName: 'ID', width: 90 },
    // { field: 'sample_id', headerName: 'Sample ID', width: 150 },
    // { field: 'processing_output_id', headerName: 'Processing Output ID', width: 150 },
    { field: 'observer_name', headerName: 'Observer Name', width: 250 },
    { field: 'number_of_occurrences', headerName: '# Occurrences', width: 150 },
    // { field: 'perc_identity', headerName: 'Percentage Identity', width: 150 },
    { field: 'recorded_db_taxon_name', headerName: 'Recorded DB Taxon Name', width: 300 },
    // { field: 'recorded_taxon_authority', headerName: 'Recorded Taxon Authority', width: 150 },
    // { field: 'recorded_taxon_attribute_qualifier', headerName: 'Recorded Taxon Attribute Qualifier', width: 150 },
    { field: 'uksi_taxon_name', headerName: 'UKSI Taxon Name', width: 150 },
    { field: 'uksi_taxon_authority', headerName: 'UKSI Taxon Authority', width: 300 },
    // { field: 'uksi_taxon_attribute_qualifier', headerName: 'UKSI Taxon Attribute Qualifier', width: 150 },
    { field: 'uksi_taxon_id', headerName: 'UKSI Taxon ID', width: 150 },
    // { field: 'evidence_link', headerName: 'Evidence Link', width: 150 },
    { field: 'datetime', headerName: 'Datetime', width: 150 }
];

const ViewObservations = () => {
    const [observations, setObservations] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const navigate = useNavigate();

    useEffect(() => {
        showAllObservations(page);
    }, [page, sortModel, pageSize]);

    const showAllObservations = async (page: number) => {
        try {
            setLoading(true);
            const sort = sortModel.length > 0 ? sortModel[0].field : '_id';
            const order: 'asc' | 'desc' = sortModel.length > 0 && sortModel[0].sort === 'asc' ? 'asc' : 'desc';
            const response: PageType<FullObservationResponse> = await getAllObservations(page, pageSize, sort, order);
            setObservations(response.content.map((observation) => {
                return {
                    id: observation.id,
                    _id: observation.id,
                    // sample_id: observation.sample_id,
                    // processing_output_id: observation.processing_output_id,
                    observer_name: observation.observer_name,
                    number_of_occurrences: observation.number_of_occurrences ? observation.number_of_occurrences : 'n/a',
                    // perc_identity: observation.perc_identity,
                    recorded_db_taxon_name: observation.recorded_db_taxon_name,
                    // recorded_taxon_authority: observation.recorded_taxon_authority,
                    // recorded_taxon_attribute_qualifier: observation.recorded_taxon_attribute_qualifier,
                    uksi_taxon_name: observation.uksi_taxon_name,
                    uksi_taxon_authority: observation.uksi_taxon_authority,
                    // uksi_taxon_attribute_qualifier: observation.uksi_taxon_attribute_qualifier,
                    uksi_taxon_id: observation.uksi_taxon_id,
                    // evidence_link: observation.evidence_link,
                    datetime: observation.datetime ? observation.datetime : 'n/a'
                }
            }));
            setTotalElements(response.totalSize);
            setTotalPages(response.totalPages);
            setLoading(false);
        } catch (err: any) {
            setErrors([...errors, err.toString()]);
            setLoading(false);
        }
    };

    return (
        <Grid container direction="column" style={{ paddingLeft: 20 }}>
            <Grid item id="heading">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h3" gutterBottom>Observations</Typography>
                    <Button variant="contained" color="warning" startIcon={<CloudUploadIcon />} onClick={() => navigate('/eDNA/upload-observations')}>Upload Observation</Button>
                </Stack>
            </Grid>
            <Grid item id="data" height={600}>
                <DataGrid
                    rows={observations}
                    getRowHeight={() => 'auto'}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={setPageSize}
                    loading={loading}
                    pagination={true}
                    paginationMode="server"
                    rowCount={totalElements}
                    onPageChange={(page) => setPage(page)}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    disableColumnFilter={true}
                />
            </Grid>
        </Grid>
    );
};

export default ViewObservations;